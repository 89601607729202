import React from "react"
import { graphql } from "gatsby"

import Kunst from "../components/kunst"
// import Services from "../components/services"

const KunstPage = ({ data }) => {
  return (
    <>
      <Kunst
        title="Meine Kunst"
        arts={data.allKunstJson.edges}
      />
      {/* <Services /> */}
    </>
  )
}
export const KunstQuery = graphql`
  query AllKunstQuery {
    allKunstJson {
      edges {
        node {
          title
          slug
          category
          description
          img {
            src
           
          }
        }
      }
    }
  }
`
export default KunstPage

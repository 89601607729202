import React, { Component } from "react"
import { Container, Badge, Row, Col } from "reactstrap"
import "./kunst.scss"

import ArtCard from "./artCard"

// const KunstCategories = ["Alle", "Lampe", "Konstruktion", "Holz"]
class Kunst extends Component {
  state = {
    category: "Alle",
    title: this.props.title,
  }
  render() {
    const { category, title } = this.state
    const arts = this.props.arts
    return (
      <div className="project-kunst section-lg" id="project">
        <Container>
          <h2 className="h-title header-title text-center font-weight-bold text-uppercase">
            {title}
          </h2>
          {/* <section className="gallery-cat text-center">
            {KunstCategories.map((KunstCategory, index) => {
              return (
                <Badge
                  key={index}
                  color={KunstCategory === category ? "secondary" : "light"}
                  onClick={() => this.setState({ category: KunstCategory })}
                >
                  {KunstCategory}
                </Badge>
              )
            })}
          </section> */}
          <section className="gallery">
            <Row>
              {category === "Alle"
                ? arts.map(arts => {
                    return (
                      <Col lg="4" md="6" sm="12">
                        <ArtCard arts={arts.node} />
                      </Col>
                    )
                  })
                : arts
                    .filter(arts => arts.node.category.includes(category))
                    .map(arts => {
                      return (
                        <Col lg="4" md="6" sm="12">
                          <ArtCard arts={arts.node} />
                        </Col>
                      )
                    })}
            </Row>
          </section>
        </Container>
      </div>
    )
  }
}

export default Kunst
